import request from "@/utils/request"

// 获取工作台数据
export function getCockpitData(data) {
  return request({
    url: '/workbench/getDataStatisticsResult',
    method: 'get',
    params: data
  })
}

// 获取服务数据
export function getUserCurveChart(data) {
  return request({
    url: '/workbench/getUserCurveChart',
    method: 'get',
    params: data
  })
}

// 获取用户数据
export function getServiceDataResult(data) {
  return request({
    url: '/workbench/getServiceDataResult',
    method: 'get',
    params: data
  })
}
